<template>
    <router-view
        class="fhf"
        ref="routeview"
        v-bind="mappedDetails"
    ></router-view>
</template>

<script>
export default {
    name: 'ProductContentMarketplaceProductView',
    props: {
        marketplaceProductId: {
            required: false,
            default: null,
        },
    },
    data () {
        return {
            breadcrumbElement: {
                name: '...',
            },
        }
    },
    computed: {
        mappedDetails () {
            let product = this.$store.state.productContent.marketplaceProducts[this.marketplaceProductId]

            if (!product) {
                product = {}
            }

            if (!product.productContent) {
                product.productContent = {

                }
            }
            return product
        },
    },
    created () {
        if (this.marketplaceProductId) {
            this.$store.dispatch('productContent/getMarketplaceProductContentById', this.marketplaceProductId).then(marketplaceProduct => {
                this.breadcrumbElement.name = marketplaceProduct.productName
            })
        }

        this.$store.dispatch('productContent/getSubmissionSpecifications')
        this.$store.dispatch('productContent/getFaqs')
    },
}
</script>
